





















































































import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import firebase from "firebase/app";
import "firebase/auth";
import {
  Configuration,
  ProfileApiFactory,
} from "@shared_vue/openapi/myvaultapi";
import { RegisterDTO } from "@shared_vue/types/RegisterDTO";
import { LoginState } from "@/store/auth/types";
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import TbButton from "@/views/TbButton.vue";
import OkModal from "@/views/widgets/modals/OkModal.vue";

@Component({
  components: {TbButton, OkModal},
  mixins: [validationMixin],
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      cellPhone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        validCell: helpers.regex("validCell", /(27|0)[0-9]{9}/),
      },
      email: {
        required,
        email,
      },
      accept: {
        required,
        mustAccept: (val) => val,
      },
    },
  },
})
export default class RegisterOne extends Vue {
  private ui = vxm.ui;
  private form = this.getEmptyForm();
  private submitted = false;
  private showError = false;
  private showWarning: boolean = false;
  private errorMessage = "ERROR";
  private profileApi = ProfileApiFactory(
    <Configuration>{ basePath: process.env.VUE_APP_MYVAULT_API_URL },
    process.env.VUE_APP_MYVAULT_API_URL
  );
  private auth = vxm.auth;
  private page = 1;
  private provider: GoogleAuthProvider | undefined;
  private toggleAside() {
    this.ui.toggleAside();    
  }

  getEmptyForm() {
    return {
      firstName: "",
      lastName: "",
      cellPhone: "",
      email: "",
      accept: false,
    };
  }

  get formString() {
    return JSON.stringify(this.form, null, 4);
  }

  get isValid() {
    return !this.$v.form.$invalid;
  }

  get isDirty() {
    return this.$v.form.$anyDirty;
  }

  checkIfValid(fieldName: any) {
    const field = this.$v.form[fieldName];
    if (field == undefined) {
      return false;
    }
    return !field.$dirty ? null : !(field.$invalid || field.$model === "");
  }

  // Docs: https://source.corp.google.com/piper///depot/google3/third_party/devsite/firebase/en/docs/auth/web/google-signin.md

  googleProvider() {
    // [START auth_google_provider_create]
    this.provider = new firebase.auth.GoogleAuthProvider();
    // [END auth_google_provider_create]

    // [START auth_google_provider_scopes]
    this.provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    this.provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    // [END auth_google_provider_scopes]
  }

  private googleSignInPopup() {
    // [START auth_google_signin_popup]
    firebase
      .auth()
      .signInWithPopup(this.provider!)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential: firebase.auth.AuthCredential | null = result.credential;

        console.log(result);
        console.log(credential);
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential!.providerId;
        // The signed-in user info.
        var user = result.user;
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
    // [END auth_google_signin_popup]
  }
  async submitgoogle() {
    this.googleSignInPopup();
  }

  async submit() {
    if (this.isValid) {
      localStorage.setItem("tempemail", this.form.email);
      let dto = new RegisterDTO(
        this.form.firstName,
        this.form.lastName,
        this.form.email,
        this.form.cellPhone
      );
      this.submitted = true;
      vxm.auth.setState(LoginState.Registering);
      try {
        vxm.auth
          .register(dto)
          .then(
            //register will now return a custom token to sign in
            (res) => {
              console.log("Succeeded with " + res);
              if (res) {
                //go to next screen. IRL more stuff would happen there.
                vxm.ui.RegisterState = 2;
              }
            }
          )
          .catch((err) => {            
            this.showError = true;
            console.log("Failed with error. status: " + err.response?.status??'None');
            if (err.response && err.response.status==400){
              //depends now if user is a member or a contact
              if (err.response.data.startsWith('User already exists. Member')){ 
                this.errorMessage = "Already member";
                if (err.response.data.endsWith('BrokenRegistration')){
                  //prompt for OTP then set vxm.ui.RegisterState = 2;
                  this.errorMessage = "A member with this data already exists but registration was not complete. We have sent a sign in link. Please check your email";
                }
                else if (err.response.data.endsWith('SigninByEmail')){
                  // just say we have sent you a registration link
                  this.errorMessage= "A member with this data already exists. We have sent a sign in link. Please check your email";
                }
              } else if (err.response.data.startsWith('User already exists. Contact')){
                this.errorMessage = "Only a contact";
              }
            } else {
              //general failure contact support
              this.errorMessage = "Something went wrong. Please contact support.";
              
            }
            
          })
      } catch (err) {        
        console.log("Failed with " + err)
      }
    }
  }

  validate() {
    this.$v.$touch();
  }

  reset() {
    this.form = this.getEmptyForm();
    this.submitted = false;
    this.$v.$reset();
  }

  mounted() {
    // console.log("hello. Why are you reading this?");
    this.googleProvider();
    if (this.$route.query.registerlink) {
      //prepopulate some data
      let name = <string>this.$route.query.name;
      let cell = <string>this.$route.query.cell;
      let secret = <string>this.$route.query.secret;
      let firstName = name.split('-')[0];
      let lastName = name.split('-')[1];
      console.log('got new data to register: ' + firstName + ' ' + lastName + ' ' + cell + ' ' + secret);
      this.form.firstName = firstName;
      this.form.lastName = lastName;
      this.form.email = '';
      this.form.cellPhone = cell;
      this.validate();
    }
  }
  
}
