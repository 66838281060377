































import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers, maxLength} from "vuelidate/lib/validators"
import {PrimaryCellData} from "@shared_vue/types/PrimaryCellData";
import {UserManagerApiFactory} from "@shared_vue/openapi/userapi";
import {Configuration as UserConfiguration} from "@shared_vue/openapi/userapi/configuration"

@Component({
    components: {},
    mixins: [validationMixin],
    validations: {
        form: {
          cellPhone: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
            validCell: helpers.regex('validCell',/(27|0)[0-9]{9}/)
          },
        }
    }
})

export default class RegisterCellOnly extends Vue {
    private ui = vxm.ui;
    private form = this.getEmptyForm();
    private submitted = false;
    private page = 1;
    private userApi = UserManagerApiFactory(<UserConfiguration>{basePath:process.env.VUE_APP_USER_API_URL},process.env.VUE_APP_USER_API_URL);

    private toggleAside() {
        this.ui.toggleAside();
    }

    getEmptyForm() {
        return {
          cellPhone: ""
        }
    }


    get formString() {
        return JSON.stringify(this.form, null, 4);
    }

    get isValid() {
        return !this.$v.form.$invalid;
    }

    get isDirty() {
        return this.$v.form.$anyDirty;
    }

    checkIfValid(fieldName: any) {
        const field = this.$v.form[fieldName]
        if (field == undefined) {
            return false;
        }
        return !field.$dirty ? null : !(field.$invalid || field.$model === '');
    }

    submit() {
      if (this.isValid) {
        this.submitted=true;
          let body = new PrimaryCellData(this.form.cellPhone)
          this.userApi.userManagerSetPrimaryCell(body).then(
              res=>{
                console.log('Succeeded with ' + res);
                if (res.status==200){
                  //go to next screen. IRL more stuff would happen there.
                  vxm.ui.RegisterState=2; //go to OTP
                }
              }
          ).catch(err=>console.log('Failed with err'));
      }
    }

    validate() {
        this.$v.$touch()
    }

    reset() {
        this.form = this.getEmptyForm()
        this.submitted = false
        this.$v.$reset()
    }

}
