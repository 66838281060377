class RegisterDTO {
    name: string;
    surname: string;
    email: string;
    cell: string;
    constructor(name:string, surname:string, username: string, cell:string) {
        this.name=name;
        this.surname=surname;
        this.email=username;
        this.cell=cell;
    }
}

export { RegisterDTO };
