class SetPasswordDTO
{
    uid: string;
    password: string;
    confirmpassword: string;

    constructor(uid: string, password: string, confirmpassword: string){
        this.uid = uid;
        this.password = password;
        this.confirmpassword = confirmpassword;
    }

}

export { SetPasswordDTO };
