class PrimaryCellData
{
    cell: string;

    constructor(cell:string) {
        this.cell=cell;
    }
}

export { PrimaryCellData };
